import Layout from "components/layout";
import React from "react";
import Breadcrumb from "components/LegalPolicies/Breadcrumb";
import SEO from "components/seo";
import backToTopIcon from "assets/backToTop.svg";

function Privacy() {
  const data = [
    {
      title: "Secure Data Handling",
      description: [
        `We follow industry best practices to handle and store your data securely. This includes:`,
      ],
    },

    {
      title: "Data Encryption",
      description: [
        `We use strong encryption methods to protect data in transit and at rest. This ensures that your information remains confidential and is only accessible to authorized individuals.`,
      ],
    },
    {
      title: "Access Control",
      description: [
        `Access to your data is restricted to authorized personnel who need it to perform their duties. We implement strict access controls to prevent unauthorized access or data breaches.`,
      ],
    },
    {
      title: "Employee Training",
      description: [
        `Our employees undergo regular security training to ensure they are aware of the latest security threats and best practices. This helps maintain a strong security posture across the organization.`,
      ],
    },
    {
      title: "Secure Transmission",
      description: [
        `When you interact with our website, your data is transmitted securely through encrypted connections. We utilize Secure Socket Layer (SSL) technology to ensure that the information you provide is protected during transmission.`,
      ],
    },

    {
      title: "Regular Security Audits and Testing",
      description: [
        `We conduct regular security audits and vulnerability assessments to identify and address potential weaknesses in our systems. This proactive approach allows us to maintain a robust security infrastructure and promptly address any vulnerabilities that may arise.`,
      ],
    },
    {
      title: "Incident Response",
      description: [
        `Despite our best efforts, security incidents can occur. In such cases, we have a comprehensive incident response plan in place. This plan outlines the steps we take to mitigate the impact of an incident, notify affected parties, and take corrective actions to prevent similar incidents in the future.`,
      ],
    },
    {
      title: "Third-Party Security",
      description: [
        `We hold our third-party partners and service providers to high security standards. Before engaging with any third party, we assess their security practices to ensure they meet our requirements for data protection.`,
      ],
    },
    {
      title: "Data Privacy",
      description: [
        `Our data privacy practices are aligned with relevant data protection laws and regulations. We respect your privacy and only collect, process, and store data that is necessary for providing our services. For more information about how we handle your data, please refer to our Privacy Policy.`,
      ],
    },
    {
      title: "User Responsibilities",
      description: [
        `While we take comprehensive security measures, safeguarding your information also depends on your actions. We encourage you to:`,
      ],
      list: [
        "Use strong, unique passwords for your accounts.",
        "Keep your login credentials confidential and avoid sharing them.",
        "Be cautious when clicking on links from unknown sources or downloading files.",
        "Regularly update your devices and software to benefit from the latest security patches.",
      ],
    },
    {
      title: "Contact Us",
      description: [
        `If you have any questions, concerns, or suspect any security issues related to our services, please contact our team at support@tomedes.com.`,
        "At Tomedes Ltd., we remain committed to maintaining the highest standards of information security to ensure your peace of mind when using our services.",
      ],
    },
  ];
  return (
    <>
      <SEO
        title=" Security Policy - Tomedes"
        description="Discover Tomedes' rigorous approach to information security. Learn how we encrypt data, control access, conduct audits, and prioritize user data protection"
        keywords=""
        slug="/legal/information-security"
      />
      <Layout>
        <div className="max-w-7xl p-4 mx-auto space-y-8 py-12">
          <Breadcrumb path={["Legal Policies", "Information Security"]} />
          <div>
            <h1 className="text-5xl md:leading-none leading-[50px] font-bold">
              Information Security at Tomedes Ltd.
            </h1>
            <p className="font-opensans mt-4">
              At Tomedes Ltd., we prioritize the security and privacy of our
              users' information.
            </p>
            <p className="font-opensans mt-4">
              This Information Security page outlines our commitment to
              safeguarding your data and the measures we take to ensure its
              protection. We understand the importance of maintaining the
              confidentiality, integrity, and availability of the information
              you entrust to us.
            </p>
          </div>
          <div className="flex flex-col mt-[60px] gap-8">
            {data.map((item, i) => (
              <div
                key={i}
                className={`rounded-lg  first:mt-10  mt-7 text-[#131313] ${
                  i > 0 && i < 4 && " pl-[40px]"
                }`}
              >
                <h1
                  className={`${
                    i > 0 && i < 4 ? " text-3xl" : "text-4xl"
                  } font-primary font-bold `}
                >
                  {item.title}
                </h1>

                {item?.description?.map((text, i) => (
                  <p key={i} className="font-opensans leading-8 mt-5 ">
                    {text}
                  </p>
                ))}

                {item?.list &&
                  item?.list?.map((text, i) => (
                    <li key={i} className="font-opensans leading-8 mt-5 ml-5 ">
                      {text}
                    </li>
                  ))}
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center mt-[50px]">
            <img
              src={backToTopIcon}
              alt="backIcon"
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <span className="mt-4 font-primary">Back To Top</span>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Privacy;
